<template>
	<BannerShortcutForm
		:is-loading="isLoading"
		:banner-type="bannerType"
		@onSubmit="handleSubmit"
	/>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import BannerShortcutForm from '@/components/BannerShortcutForm.vue';

import { BANNER_TYPE } from '../enums/banners';
import { scrollToTop } from '../assets/js/helpers';

export default {
	name: 'BannerShortcutCreate',

	components: {
		BannerShortcutForm,
	},

	props: {
		bannerType: {
			type: String,
			default: BANNER_TYPE.shortcut,
		},
	},

	computed: {
		...mapState('banners', {
			create: 'create',
		}),

		isLoading() {
			return this.create.isLoading;
		},
	},

	methods: {
		...mapActions({
			showToast: 'toast/showToast',
			createBannerGroup: 'banners/createBannerGroup',
		}),

		async handleSubmit(data) {
			try {
				await this.createBannerGroup(data);

				this.$router.push({ name: 'BannerShortcutList' });
			} catch {
				scrollToTop();
			}
		},
	},
};
</script>
